import Axios from "axios"
import { GET_PAYMENT, GET_PAYMENT_STATUS, UPDATE_STATUS } from "./actions"


  //const URL = 'https://dev-payment.wepay4u.com/api/v1/conciliation/'
  const URL = 'https://stg-payment.wepay4u.com/api/v1/conciliation/'
  //const URL = 'https://payment.wepay4u.com/api/v1/conciliation/'

export const getPayment = (idMerchant) => dispatch => {
    Axios.get(`${URL}${idMerchant}`).then( response => {
        return dispatch({
            type: GET_PAYMENT,
            payment: response.data
        })
    })
}

export const getPaymentStatus = () => dispatch => {
    Axios.get(`${URL}`).then( response => {
        return dispatch({
            type: GET_PAYMENT_STATUS,
            paymentStatus: response.data
        })
    })
}


export const updateStatus = newStatus => ({
    type: UPDATE_STATUS,
    data: newStatus
})
